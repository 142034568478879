<template>
 <div class="box box-primary">
    <div class="box-header with-border">
        <i class="fa fa-user"></i>
        <h3 class="box-title text-right">User Type Configuration</h3>
    </div>
    <div class="box-body">
  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label class="control-label text-left">User Type Name</label>
        <input id="name" class="form-control" type="text">
        <!--v-if-->
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <h4> Access Rights <span class="data-v-tooltip" data-v-tooltip="Access rights determine the pages that users can view and submit. Click on an access right name to view its description." style="--v-tooltip-arrow-display: inline; --v-tooltip-left: 50%; --v-tooltip-top: 100%; --v-tooltip-translate: translate(-50%, 10%); --v-tooltip-arrow-border-color: transparent transparent var(--v-tooltip-background-color) transparent; --v-tooltip-arrow-top: calc(var(--v-tooltip-top) - var(--v-tooltip-top-offset) - 7px);">
          <i class="fa fa-question-circle text-info help-icon"></i>
        </span>
      </h4>
      <div class="form-group">
        <label class="">
          <input type="checkbox" class="form-check-input" value="55"> Chat.Answer </label>
      </div>
      <div class="form-group">
        <label class="">
          <input type="checkbox" class="form-check-input" value="54"> Chat.Broadcast </label>
      </div>
      <div class="form-group">
        <label class="">
          <input type="checkbox" class="form-check-input" value="18"> Tab.Admin.AdditionalDetails </label>
      </div>
     
    </div>
  </div>
  <div class="row">
    <div class="col-md-2 mt-3">
      <button type="button" class="btn btn-primary margin-r-5">Save</button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-10 mt-3 admin-error">
      <!--v-if-->
    </div>
  </div>
</div>
</div>
</template>